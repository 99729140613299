import { TextBox } from "@syncfusion/ej2-react-inputs";
import { Sidebar, TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import React, { PropsWithChildren, useContext, useEffect } from "react";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { Link } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import {
  SetGridState,
  getUserViewsByModuleName,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  BrowserIsDevice,
  RAFEntityName,
  RAFReportType,
  RAFTreeViewMenu,
} from "../../../../RAFComponents/helpers/Constants";
import { hasPermissions } from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import { MenuCategory } from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  ConvertToElementID,
  deepEqual,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { RAFWorkspaceRelatedtoType } from "../../../../RAFMaster/helpers/RMConstants";
import {
  atomSelectedViewState,
  getDefaultActiveCurrentViewFromList,
  getViewsReportsDashboards,
} from "../../../../RAFModules/Common/List/IndexHelper";
import { WorkspaceLeftItemsContext } from "../../../../RAFModules/Common/Providers/WorkspaceContextProvider";
import {
  PortalTreeViewItemRow,
  convertViewRowToWorkspaceViewItem,
} from "../../../../RAFModules/Common/Workspace/WorkspaceItem/WorkspaceItemHelper";
import RAFProfileCard from "../../../../helpers/RAFProfileCard/RAFProfileCard";
import { SKYPermissionConstants } from "../../../../helpers/SKYPermissionConstants";
import NavigationMenuBackButton from "./NavigationMenuBackButton";
import NavigationMenuProductLogo from "./NavigationMenuProductLogo";
import "./NavigationMenuTreeView.scss";
import "./SFNavigationMenuTreeView.scss";
import workesioShortLogo from './../../../../images/workesio logo reversal short.png'
import workesioLogo from './../../../../images/main-logo-png-dark.png'
// import workesioLogo from './../../../../images/esio_logo_white_png.png'
import skyShortWhiteLogo from './../../../../images/SkytrustLogoWhiteShort.png'

interface IProps {
  theme?: "White" | "Dark";
  mode?: "SideBar" | "FullScreen";
}
function NavigationMenuTreeView({ ...props }: PropsWithChildren<IProps>) {
  let treeviewObj: TreeViewComponent;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const hasPermissionToViewAdministration = hasPermissions(permissionValue, [
    SKYPermissionConstants.RoleList,
    SKYPermissionConstants.PortalList,
  ]);

  const workspaceLeftItemsContext = useContext(WorkspaceLeftItemsContext);
  const portalTreeViewItems = isNotNullAndUndefined(workspaceLeftItemsContext)
    ? workspaceLeftItemsContext.portalTreeViewItems
    : [];

  const currentMenuCategory =
    isNotNullAndUndefined(workspaceLeftItemsContext) &&
      isNotNullAndUndefined(workspaceLeftItemsContext.currentMenuCategory)
      ? workspaceLeftItemsContext.currentMenuCategory
      : MenuCategory.Default;

  const leftMenuItemOuterDivKey = isNotEmptyArray(portalTreeViewItems)
    ? `${currentMenuCategory}_${portalTreeViewItems.length}`
    : `${currentMenuCategory}_0`;

  useEffect(() => {
    subscribeRAFEvent(`${RAFEventName.ToggleSideMenu}`, toggleclick);

    return () => {
      unsubscribeRAFEvent(`${RAFEventName.ToggleSideMenu}`, toggleclick);
    };
  });

  let sidebarMenu;
  const productTextLogoWorkEsio = `${window.location.origin}/Content/img/main-logo-png.png`;

  // Initialization of Sidebar.

  // function refresh() {

  let width = BrowserIsDevice ? "90%" : "328px";
  // let width = BrowserIsDevice ? "90%" : "260px";

  sidebarMenu = new Sidebar({
    width: width,
    target: "#mainTabDiv",
    // mediaQuery: '(min-width: 992px)',
    isOpen: BrowserIsDevice ? false : true,
    enableGestures: false,
    // showBackdrop: BrowserIsDevice ? true : false,
  });
  // if (!BrowserIsDevice) {
  sidebarMenu.appendTo("#sideTree");
  // }
  let sidebarBackDrop = document.querySelector(".e-sidebar__backdrop");
  if (isNotNullAndUndefined(sidebarBackDrop)) {
    sidebarBackDrop.classList.remove("show");
  }

  //Defines the rendering code blocks for the Textbox component.
  let inputObj: TextBox = new TextBox({
    placeholder: "Search...",
  });
  inputObj.appendTo("#resSearch");

  function toggleclick(backDropClicked?: boolean) {
    let sidebarToggleBtn = document.getElementById("e-sidebar__toggleBtn");
    let sidebarBackDrop = document.querySelector(".e-sidebar__backdrop");
    if (sidebarMenu.isOpen === true) {
      isNotNullAndUndefined(sidebarToggleBtn) &&
        sidebarToggleBtn.classList.remove("hidden");
    } else {
      isNotNullAndUndefined(sidebarToggleBtn) &&
        sidebarToggleBtn.classList.add("hidden");
    }
    if (BrowserIsDevice === true && isNotNullAndUndefined(sidebarBackDrop)) {
      if (backDropClicked === true) {
        sidebarBackDrop.classList.remove("show");
        sidebarMenu.isOpen = false;
      } else if (sidebarMenu.isOpen === true) {
        sidebarBackDrop.classList.remove("show");
        sidebarMenu.isOpen = false;
      } else {
        sidebarBackDrop.classList.add("show");
        sidebarMenu.isOpen = true;
      }
    } else {
      sidebarMenu.isOpen = !sidebarMenu.isOpen;
    }
  }

  const onClickLeftMenuItem = useRecoilCallback(
    ({ set, snapshot }) =>
      async (item: PortalTreeViewItemRow) => {
        if (isNotNullAndUndefined(item)) {
          //if (item.ItemType === RAFWorkspaceRelatedtoType.View) {
          const prevCurrentView: RAFTreeViewMenu = await snapshot.getPromise(
            atomSelectedViewState(item.Entity)
          );
          const recentViewId =
            item.ItemType === RAFWorkspaceRelatedtoType.View
              ? item.UID
              : isNotNullAndUndefined(prevCurrentView)
                ? prevCurrentView.UID
                : null;

          const recentViewName =
            item.ItemType === RAFWorkspaceRelatedtoType.View
              ? item.Name
              : isNotNullAndUndefined(prevCurrentView)
                ? prevCurrentView.Name
                : null;

          const recentViewType =
            item.ItemType === RAFWorkspaceRelatedtoType.View
              ? null
              : isNotNullAndUndefined(prevCurrentView)
                ? prevCurrentView.Category
                : null;

          const viewMenuDataSource = await getViewsReportsDashboards(
            item.Entity,
            true,
            true,
            null
          );

          const currentView = await getDefaultActiveCurrentViewFromList(
            item.Entity,
            recentViewId,
            RAFReportType.Table,
            viewMenuDataSource,
            true,
            permissionValue,
            recentViewName
          );


          if (
            isNotNullAndUndefined(currentView) &&
            !deepEqual(prevCurrentView, currentView)
          ) {
            set(atomSelectedViewState(item.Entity), currentView);
            const currentModule = item.Entity;
            const defaultListGridID = `grid3_${currentModule}_mainList`;
            SetGridState(defaultListGridID, null, null);
          }

          if (isNotNullAndUndefined(currentView)) {
            const treeViewItem = getSelectedItemByCurrentView(currentView);
            if (isNotNullAndUndefined(treeViewItem)) {
              const currentViewID = treeViewItem.UID ?? treeViewItem.Name;
              updateTreeViewSelectExpandStateByID(currentViewID);
            } else {
              const isLoaded = await loadChildViewItem(item.UID);
              const treeViewItem = getSelectedItemByCurrentView(currentView);
              if (isNotNullAndUndefined(treeViewItem)) {
                const currentViewID = treeViewItem.UID ?? treeViewItem.Name;
                updateTreeViewSelectExpandStateByID(currentViewID);
              } else {
                updateTreeViewSelectExpandStateByID(item.UID);
              }
            }
          } else {
            updateTreeViewSelectExpandStateByID(item.UID);
          }
        }
      }
  );

  function nodeTemplate(item: PortalTreeViewItemRow) {
    const fontsize = "1em";
    const displayName = item.Text ?? "na";
    return (
      <Link
        to={item.URL}
        key={item.URL}
        {...(item.Name === "XeroIntegrations" ? { target: "_blank" } : {})}
        id={ConvertToElementID("sidebarlist" + displayName)}
        onClick={(e) => {
          let isSelectedMenuItem = false;

          if (
            isNotNullAndUndefined(item.URL) &&
            item.URL.toLowerCase() === window.location.pathname.toLowerCase()
          ) {
            isSelectedMenuItem = true;
          }

          if (isSelectedMenuItem) {
            e.preventDefault();
          }
          onClickLeftMenuItem(item);
          if (
            BrowserIsDevice &&
            (isNotNullAndUndefined(item.ParentUID) ||
              item.HasChildren === false)
          ) {
            toggleclick();
          }
          // if ((item.ItemType === RAFWorkspaceRelatedtoType.View || item.HasChildren === false) && !isSelectedMenuItem && !IsNullOrWhiteSpace(item.URL)) {
          //     onClickLeftMenuItem(item);
          // } else {
          //     e.preventDefault();
          // }
        }}
      >
        <div
          className={`menu-item-text`}
        // className={`menu-item-text d-flex ${isNotNullAndUndefined(item.ParentUID) ? "align-items-center" : "align-items-baseline"}`}
        // onClick={() =>
        //     onSelectViewItem(item)
        // }
        >
          <div className="d-flex align-items-center position-relative">
            <span
              className={"transparent-avatar avatar-text-square"}
              style={{
                width: "24px",
                height: "24px",
                minWidth: "24px",
                maxWidth: "24px",
                fontSize: fontsize,
                fontWeight: 600,
                //marginRight: "0.625em",
              }}
            >
              {item.Name === 'Classic' ?
                <img src={skyShortWhiteLogo} width={16} />
                :
                
              item.Name === RAFEntityName.InspectionLibraryCategoryType ?
              <img src={workesioShortLogo} width={24} />
              :
                (isNotNullAndUndefined(item) &&
                  isNotNullAndUndefined(item.IsExternalIcon) &&
                  isNullOrUndefined(item.ParentUID) &&
                  item.IsExternalIcon === true ? (
                  <IconPickerItem
                    icon={
                      isNotNullAndUndefined(item) &&
                        isNotNullAndUndefined(item.Icon)
                        ? (item.Icon as IconList)
                        : "FaBriefcase"
                    }
                    size={15}
                    color={"#fff"}
                    containerStyles={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                ) : (
                  <span
                    className={
                      item.Name === "home"
                        ? "text-white fa fa-" + item.Icon
                        : isNotNullAndUndefined(item.ParentUID)
                          ? "fa-xsm text-white far fa-circle"
                          : "text-white fa fa-" + item.Icon
                    }
                  ></span>
                ))}
            </span>
          </div>
          <div
            className={`e-sidebar__treeview__menuItem__text ecllipseFirstLine${isNotNullAndUndefined(item.ParentUID) ? " ps-0" : ""
              }`}
          >
            {displayName}
          </div>
          {/* {(item.Name === RAFEntityName.InspectionLibraryCategoryType || item.Name === RAFEntityName.InspectionLibrary) && 
          <div className="raf_badge raf_18 raf_badge_rgba content_neutral_white raf_badge_beta ms-2"><span>New</span></div>
          } */}
        </div>
      </Link>
    );
  }

  const nodeExpanding = async (args) => {
    if (isNotNullAndUndefined(args) && args.isInteracted == true) {
      const nodeDataID = isNotNullAndUndefined(args.nodeData)
        ? args.nodeData.id
        : null;
      if (isNotNullAndUndefined(nodeDataID)) {
        const isLoaded = await loadChildViewItem(nodeDataID);
        if (isNotNullAndUndefined(treeviewObj)) {
          const selectedNodes = treeviewObj.selectedNodes;
          if (isNotEmptyArray(selectedNodes)) {
            updateTreeViewSelectExpandStateByID(selectedNodes[0]);
          }
        }
      }
    }
  };

  const updateTreeViewSelectExpandStateByID = (nodeId: string) => {
    if (isNotNullAndUndefined(treeviewObj) && isNotNullAndUndefined(nodeId)) {
      const currentNode = getTreeViewNodeDataByNodeID(nodeId);
      const treeSelectedNodeID = isNotEmptyArray(treeviewObj.selectedNodes)
        ? treeviewObj.selectedNodes[0]
        : null;
      const treeExpandedNodesID = isNotEmptyArray(treeviewObj.expandedNodes)
        ? treeviewObj.expandedNodes[0]
        : null;
      if (isNotNullAndUndefined(currentNode)) {
        const hasChildren = isNotEmptyArray(currentNode.NodeChild)
          ? true
          : false;
        if (hasChildren === true) {
          if (treeExpandedNodesID !== currentNode.UID) {
            treeviewObj.expandedNodes = [currentNode.UID];
          }
        } else if (isNotNullAndUndefined(currentNode.ParentUID)) {
          if (treeExpandedNodesID !== currentNode.ParentUID) {
            treeviewObj.expandedNodes = [currentNode.ParentUID];
          }
        }
        treeviewObj.selectedNodes = [currentNode.UID];
      }
    }
  };

  const onNodeSelect = async (args) => {
    const nodeData = isNotNullAndUndefined(args) ? args.nodeData : null;
    const nodeDataID = isNotNullAndUndefined(nodeData) ? nodeData.id : null;
    if (isNotNullAndUndefined(nodeDataID)) {
      const isLoaded = await loadChildViewItem(nodeDataID);
      if (nodeData.HasChildren === true) {
        treeviewObj.expandedNodes = [nodeDataID];
      }
    }
  };

  const loadChildViewItem = (selectedNodeId: string) => {
    return new Promise<boolean>(async (resolve) => {
      let isLoaded = false;
      if (
        isNotNullAndUndefined(selectedNodeId) &&
        isNotNullAndUndefined(treeviewObj)
      ) {
        const allNodeDate: PortalTreeViewItemRow[] =
          treeviewObj.getTreeData() as any;
        const currentNode = getTreeViewNodeDataByNodeID(selectedNodeId);
        if (
          isNotNullAndUndefined(currentNode) &&
          isNotNullAndUndefined(currentNode.Entity) &&
          currentNode.HasChildren === true &&
          currentNode.ItemType === RAFWorkspaceRelatedtoType.Module
        ) {
          const currentNodeChildItems = isNotNullAndUndefined(currentNode)
            ? currentNode.NodeChild
            : [];
          const childItemInTree: PortalTreeViewItemRow[] = isNotEmptyArray(
            allNodeDate
          )
            ? allNodeDate.filter((x) => x.ParentUID === selectedNodeId)
            : [];
          const isChildExist =
            isNotEmptyArray(childItemInTree) ||
              isNotEmptyArray(currentNodeChildItems)
              ? true
              : false;
          if (isChildExist === false) {
            const selectedWorkspaceItem = portalTreeViewItems.find(
              (x) => x.UID === selectedNodeId
            );
            const entity = selectedWorkspaceItem.Entity;
            if (isNotNullAndUndefined(entity)) {
              const viewItems = await getUserViewsByModuleName(entity, true);
              const viewMenusRow = convertViewRowToWorkspaceViewItem(
                viewItems,
                entity,
                selectedWorkspaceItem.UID,
                selectedWorkspaceItem.URL,
                permissionValue
              );
              if (
                isNotEmptyArray(viewMenusRow) &&
                isNotNullAndUndefined(treeviewObj)
              ) {
                treeviewObj.addNodes(
                  viewMenusRow as any,
                  selectedNodeId.toString()
                );
                isLoaded = true;
              }
            }
          }
        }
      }
      resolve(isLoaded);
    });
  };

  const onTreeViewDataBound = () => {
    setTimeout(() => {
      if (isNotEmptyArray(portalTreeViewItems)) {
        const selectedItem = getSelectedItemAndParentId();
        if (isNotNullAndUndefined(selectedItem)) {
          onClickLeftMenuItem(selectedItem);
        }
      }
    }, 100);
  };

  const getTreeViewNodeDataByNodeID = (nodeId: string) => {
    let selectedItem: PortalTreeViewItemRow = null;
    if (isNotNullAndUndefined(treeviewObj)) {
      const allNodeDate: PortalTreeViewItemRow[] =
        treeviewObj.getTreeData() as any;
      if (isNotEmptyArray(allNodeDate) && isNotNullAndUndefined(nodeId)) {
        allNodeDate.forEach((item) => {
          if (item.UID === nodeId) {
            selectedItem = item;
          } else if (isNotEmptyArray(item.NodeChild)) {
            item.NodeChild.forEach((childItem) => {
              if (childItem.UID === nodeId) {
                selectedItem = childItem;
              }
            });
          }
        });
      }
    }
    return selectedItem;
  };

  const getSelectedItemByCurrentView = (currentView: RAFTreeViewMenu) => {
    let selectedItem: PortalTreeViewItemRow = null;
    if (isNotNullAndUndefined(treeviewObj)) {
      const allNodeDate: PortalTreeViewItemRow[] =
        treeviewObj.getTreeData() as any;
      if (isNotEmptyArray(allNodeDate) && isNotNullAndUndefined(currentView)) {
        const currentViewID = currentView.UID ?? currentView.Name;
        allNodeDate.forEach((item) => {
          if (item.UID === currentViewID) {
            selectedItem = item;
          } else if (isNotEmptyArray(item.NodeChild)) {
            item.NodeChild.forEach((childItem) => {
              if (childItem.UID === currentViewID) {
                selectedItem = childItem;
              }
            });
          }
        });
      }
    }
    return selectedItem;
  };

  const getSelectedItemAndParentId = () => {
    const currentPath = window.location.pathname.toLowerCase();
    let selectedItem: PortalTreeViewItemRow = null;
    if (isNotEmptyArray(portalTreeViewItems)) {
      portalTreeViewItems.forEach((item) => {
        if (
          isNotNullAndUndefined(currentPath) &&
          isNotNullAndUndefined(item.URL) &&
          item.URL.toLowerCase() === currentPath.toLowerCase()
        ) {
          selectedItem = item;
        } else if (isNotEmptyArray(item.NodeChild)) {
          item.NodeChild.forEach((childItem) => {
            if (
              isNotNullAndUndefined(currentPath) &&
              isNotNullAndUndefined(childItem.URL) &&
              childItem.URL.toLowerCase() === currentPath.toLowerCase()
            ) {
              selectedItem = childItem;
            }
          });
        }
      });
    }
    return selectedItem;
  };

  const onChangeMenuCategory = (selectedMenu) => {
    if (selectedMenu !== currentMenuCategory) {
      setTimeout(() => {
        workspaceLeftItemsContext.updateCurrentWorkspaceLeftMenuItems();
      }, 50);
    }
  };

  // if (isNullOrUndefined(currentWorkspace)) return <div></div>;

  // else if (BrowserIsDevice && (currentTenantName === RAFTenantName.CareESIO || isCareESIOPartner === true) && props.mode !== 'FullScreen') return <div></div>;
  return (
    <>
      <aside
        id="sideTree"
        className="sidebar-treeview e-sidebar__treeview section__container"
      >
        <NavigationMenuProductLogo />
        <div className="e-sidebar__treeview__header" key={currentMenuCategory}>
          {isNullOrUndefined(currentMenuCategory) ||
            currentMenuCategory === MenuCategory.Default ||
            currentMenuCategory === MenuCategory.ContentManagement ? (
            <div className="e-sidebar__profileCardDiv w-100">
              <RAFProfileCard
                arrowTipClass="e-tip-left"
                uiMode="fullBtn"
                onUpdatMenuCategory={(menu) => onChangeMenuCategory(menu)}
                hasPermissionToViewAdministration={
                  hasPermissionToViewAdministration
                }
              />
            </div>
          ) : (
            <NavigationMenuBackButton
              onUpdatMenuCategory={(menu) => onChangeMenuCategory(menu)}
            />
          )}
        </div>
        <div
          id="mainTree"
          className="section__div customScrollBar"
          key={leftMenuItemOuterDivKey}
        >
          <TreeViewComponent
            ref={(treeview) => (treeviewObj = treeview)}
            fields={{
              dataSource: portalTreeViewItems as any,
              id: propertyOf<PortalTreeViewItemRow>("UID"),
              parentID: propertyOf<PortalTreeViewItemRow>("ParentUID"),
              hasChildren: propertyOf<PortalTreeViewItemRow>("HasChildren"),
              text: propertyOf<PortalTreeViewItemRow>("Text"),
              child: propertyOf<PortalTreeViewItemRow>("NodeChild"),
              iconCss: propertyOf<PortalTreeViewItemRow>("Icon"),
            }}
            nodeTemplate={nodeTemplate.bind(this)}
            nodeExpanding={nodeExpanding}
            //nodeSelected={onNodeSelect}
            loadOnDemand={true}
            dataBound={onTreeViewDataBound}
            enablePersistence
          />
        </div>
        <div className="sidebar-nav__workEsioLogo">
          <span className="sidebar-nav__workEsioLogo__text">Powered by</span>
          <img
            src={workesioLogo}
            alt="Product Logo"
            className="sidebar-nav__workEsioLogo__img"
          />
        </div>
      </aside>
      {/* <ButtonComponent
        type="button"
        iconCss="fas fa-angles-right"
        id="e-sidebar__toggleBtn"
        cssClass={BrowserIsDevice ? "e-sidebar__toggleBtn" : "e-sidebar__toggleBtn hidden"}
        onClick={() => toggleclick()}
      ></ButtonComponent> */}
      <div
        className="e-sidebar__backdrop"
        onClick={() => toggleclick(true)}
      ></div>
    </>
  );
}
export default React.memo(NavigationMenuTreeView);

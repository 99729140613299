import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import "./index.css";
import {
  AzureAdB2C,
  MSAL_CONFIG,
  RAFAuthModule,
  loginRequest,
} from "./RAFAzure/AuthModule";
import { IsNullOrWhiteSpace } from "./RAFComponents/helpers/utils";
import { firebaseConfig } from "./RAFFirebase";
import reportWebVitals from "./reportWebVitals";
//import { registerLicense } from "@syncfusion/ej2-base";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { RecoilRoot } from "recoil";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { RumComponentContextProvider } from "./RAFComponents/rum-react/RumComponentContext";
import { BrowserRouter } from "react-router-dom";
import { showErrorToast } from "./RAFComponents/Utility/RAFToastComponent";
import { hideProgress, showProgress } from "./RAFComponents/helpers/AppHelper";

import("@syncfusion/ej2-base").then(ejBase => {
  ejBase.registerLicense(
    //'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0djWn1Wc31UR2dc'//26
    //'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdkdhWn5Wc3VcT2Bb'//27
    'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdkZjWXxcdHBWT2hZ'
  );
});

import("survey-core").then(surveyBase => {

  surveyBase.setLicenseKey(
    "N2E5MDA1OWYtOTVmZS00Mjc2LThmMDUtOWFkM2M0OWJmNmU5OzE9MjAyNS0wNS0wOQ=="
  );
});

const MAX_RETRIES = 3; // Maximum number of retries
let retryCount = 0; // Current retry count



export let msalInstance = new RAFAuthModule(MSAL_CONFIG);


// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (!msalInstance.getActiveAccount() && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS)
    && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
  // else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
  //   console.log('event.eventType === EventType.ACQUIRE_TOKEN_FAILURE', event);
  //   if (event.error instanceof InteractionRequiredAuthError) {
  //     msalInstance.loginRedirect(loginRequest);
  //   }
  //   else if (event.error['errorCode'] === 'monitor_window_timeout') {
  //     console.error('Network error occurred:', event.error);
  //     if (event.payload) {

  //       if (retryCount < MAX_RETRIES) {
  //         retryCount++;
  //         const payload = event.payload as AuthenticationResult;
  //         const account = payload.account;
  //         setTimeout(() => {
  //           msalInstance.acquireTokenSilent({
  //             scopes: [AzureAdB2C.AcquireTokenScope],
  //             account: account
  //           })
  //             .catch(error => {
  //               console.error('Failed to acquire token silently after retry:', error);
  //             });
  //         }, 500);
  //       } else {
  //         alert('A network error occurred. Please try again later.');
  //       }
  //     }
  //     else {
  //       console.error('Failed to acquire token:', event.error);
  //       msalInstance.loginRedirect(loginRequest);
  //     }
  //   }
  //   else if (
  //     event.error !== null
  //     && event.error !== undefined
  //     && (
  //       event.error['errorCode'] === 'invalid_grant'
  //       ||
  //       (
  //         event.error.message !== null
  //         && event.error.message !== undefined
  //         && (event.error.message.indexOf('invalid_grant') >= 0 || event.error.message.indexOf('grant has expired') >= 0)
  //       )
  //     )
  //   ) {
  //     console.error('ACQUIRE_TOKEN_FAILURE invalid_grant error occurred:', event.error);
  //     msalInstance.loginRedirect(loginRequest);
  //   }
  //   // else if (event.error['errorCode'] === 'network_error') {
  //   //   // If it's a network error, retry the operation after a delay
  //   //   console.error('Network error occurred:', event.error);
  //   //   if (retryCount < MAX_RETRIES) {
  //   //     retryCount++;
  //   //     const payload = event.payload as AuthenticationResult;
  //   //     const account = payload.account;
  //   //     setTimeout(() => {
  //   //       msalInstance.acquireTokenSilent({
  //   //         scopes: [AzureAdB2C.AcquireTokenScope],
  //   //         account: account
  //   //       })
  //   //         .catch(error => {
  //   //           console.error('Failed to acquire token silently after retry:', error);
  //   //         });
  //   //     }, 500);
  //   //   } else {
  //   //     // If the maximum number of retries has been reached, display a message to the user
  //   //     // Replace with your own error display logic
  //   //     showErrorToast('A network error occurred. Please try again later.');
  //   //   }
  //   // }
  //   // else {
  //   //   // For other types of errors, log them and display a generic message to the user
  //   //   console.error('Failed to acquire token:', event.error);
  //   //   // Replace with your own error display logic
  //   //   showErrorToast('An error occurred. Please try again later.');
  //   // }

  // }
  // else if (event.eventType === EventType.SSO_SILENT_FAILURE) {
  //   console.error('Silent single sign-on failed:', event);
  //   if (event.error && event.error['errorCode'] === 'userCancelled') {
  //     console.log('User canceled the sign-in process');
  //   } else {
  //     msalInstance.loginRedirect(loginRequest);
  //   }

  // }
  // else if (event.eventType === EventType.LOGIN_FAILURE && event.error instanceof InteractionRequiredAuthError) {
  //   msalInstance.loginRedirect(loginRequest);
  // }
  // else if (event.eventType === EventType.LOGIN_FAILURE && event.error['errorCode'] === 'monitor_window_timeout') {
  //   msalInstance.loginRedirect(loginRequest);
  // }
  // else if (event.eventType === EventType.LOGIN_FAILURE && event.error['errorCode'] === 'tokenExpired') {
  //   const payload = event.payload as AuthenticationResult;
  //   const account = payload.account;
  //   //let progressDiv = showProgress('body');
  //   msalInstance.acquireTokenSilent({
  //     scopes: [AzureAdB2C.AcquireTokenScope],
  //     account: account,
  //   }).then((response) => {
  //     //hideProgress(progressDiv);
  //     msalInstance.setActiveAccount(account);
  //     msalInstance['accessToken'] = response.accessToken;

  //   }).catch((error) => {
  //     //hideProgress(progressDiv);
  //     console.log('Token acquisition failed');
  //   });
  // }
  else if (
    event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
    event.eventType === EventType.SSO_SILENT_FAILURE ||
    event.eventType === EventType.LOGIN_FAILURE
  ) {
    console.error('Authentication error:', event.error);
    msalInstance.loginRedirect(loginRequest);
  }
});


const root = ReactDOMClient.createRoot(document.getElementById("root"));


//const app = initializeApp(firebaseConfig);
//export const firestoreDatabase = getFirestore(app);
// export const firestoreDatabase = initializeFirestore(app, {
//   ignoreUndefinedProperties: true,
// });

function MainApp() {
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {

    Promise.all([
      import("@syncfusion/ej2-base"),
      import("survey-core")
    ]).then(([ejBase, surveyBase]) => {
      ejBase.registerLicense(
        //'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0djWn1Wc31UR2dc'//26
        'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdkZjWXxcdHBWT2hZ'
      );
      surveyBase.setLicenseKey(
        "N2E5MDA1OWYtOTVmZS00Mjc2LThmMDUtOWFkM2M0OWJmNmU5OzE9MjAyNS0wNS0wOQ=="
      );
      setInitialized(true);
    }).catch(error => {
      console.error("Error loading modules:", error);
    });

  }, []);

  useEffect(() => {
    if (isInitialized) {

      import("./RAFMaster/RMModules/FormLibrary/components/SurveyCreator/Initializations").then(ejBase => {
        ejBase.initialiseRAFSurveySettings();
      });

    }
  }, [isInitialized]);

  if (!isInitialized) {
    return <p>Loading...</p>;
  }
  return (
    <ErrorBoundary>
      <RecoilRoot>
        <BrowserRouter>
          <App pca={msalInstance} />
        </BrowserRouter>
      </RecoilRoot>
    </ErrorBoundary>
  );

}

root.render(
  <MainApp />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
